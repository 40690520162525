import React from 'react'

const Details = ({other}) => {
  return (
    <div className="my-5">
        <div className="shadow-custom p-5 w-full bg-white">
          <div className="flex justify-between">
            <p className="font-bold text-slate-700">
            {
                other === "police"
                ? "Police station contact details"
                : other === "hospital"? "Hospital contact details":
                "Volunteer contact details"
            }
            </p>
          </div>
          <hr className="my-2" />
          <div className='h-[500px] overflow-auto'>
            <div className='mb-2'>
                <p className='font-semibold'>Search</p>
                <input id="search" type="text" name="type" className="w-full sm:w-1/2 bg-[#f9f4f4] border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block p-2.5"/>
            </div>
            <p className='font-semibold'>Kapodra   Shri M.B.Ausura   (P.I.Kapodra-1)   (M) 63596 29708</p>
            <p className='font-semibold'>Kapodra   Shri M.B.Ausura   (P.I.Kapodra-1)   (M) 63596 29708</p>
            <p className='font-semibold'>Kapodra   Shri M.B.Ausura   (P.I.Kapodra-1)   (M) 63596 29708</p>
            <p className='font-semibold'>Kapodra   Shri M.B.Ausura   (P.I.Kapodra-1)   (M) 63596 29708</p>
            <p className='font-semibold'>Kapodra   Shri M.B.Ausura   (P.I.Kapodra-1)   (M) 63596 29708</p>
            <p className='font-semibold'>Kapodra   Shri M.B.Ausura   (P.I.Kapodra-1)   (M) 63596 29708</p>
            <p className='font-semibold'>Kapodra   Shri M.B.Ausura   (P.I.Kapodra-1)   (M) 63596 29708</p>
            <p className='font-semibold'>Kapodra   Shri M.B.Ausura   (P.I.Kapodra-1)   (M) 63596 29708</p>
            <p className='font-semibold'>Kapodra   Shri M.B.Ausura   (P.I.Kapodra-1)   (M) 63596 29708</p>
            <p className='font-semibold'>Kapodra   Shri M.B.Ausura   (P.I.Kapodra-1)   (M) 63596 29708</p>
            <p className='font-semibold'>Kapodra   Shri M.B.Ausura   (P.I.Kapodra-1)   (M) 63596 29708</p>
            <p className='font-semibold'>Kapodra   Shri M.B.Ausura   (P.I.Kapodra-1)   (M) 63596 29708</p>
            <p className='font-semibold'>Kapodra   Shri M.B.Ausura   (P.I.Kapodra-1)   (M) 63596 29708</p>
            <p className='font-semibold'>Kapodra   Shri M.B.Ausura   (P.I.Kapodra-1)   (M) 63596 29708</p>
            <p className='font-semibold'>Kapodra   Shri M.B.Ausura   (P.I.Kapodra-1)   (M) 63596 29708</p>
            <p className='font-semibold'>Kapodra   Shri M.B.Ausura   (P.I.Kapodra-1)   (M) 63596 29708</p>
            <p className='font-semibold'>Kapodra   Shri M.B.Ausura   (P.I.Kapodra-1)   (M) 63596 29708</p>
            <p className='font-semibold'>Kapodra   Shri M.B.Ausura   (P.I.Kapodra-1)   (M) 63596 29708</p>
            <p className='font-semibold'>Kapodra   Shri M.B.Ausura   (P.I.Kapodra-1)   (M) 63596 29708</p>
            <p className='font-semibold'>Kapodra   Shri M.B.Ausura   (P.I.Kapodra-1)   (M) 63596 29708</p>
            <p className='font-semibold'>Kapodra   Shri M.B.Ausura   (P.I.Kapodra-1)   (M) 63596 29708</p>
            <p className='font-semibold'>Kapodra   Shri M.B.Ausura   (P.I.Kapodra-1)   (M) 63596 29708</p>
            <p className='font-semibold'>Kapodra   Shri M.B.Ausura   (P.I.Kapodra-1)   (M) 63596 29708</p>
            <p className='font-semibold'>Kapodra   Shri M.B.Ausura   (P.I.Kapodra-1)   (M) 63596 29708</p>
            <p className='font-semibold'>Kapodra   Shri M.B.Ausura   (P.I.Kapodra-1)   (M) 63596 29708</p>
            <p className='font-semibold'>Kapodra   Shri M.B.Ausura   (P.I.Kapodra-1)   (M) 63596 29708</p>
            <p className='font-semibold'>Kapodra   Shri M.B.Ausura   (P.I.Kapodra-1)   (M) 63596 29708</p>
            <p className='font-semibold'>Kapodra   Shri M.B.Ausura   (P.I.Kapodra-1)   (M) 63596 29708</p>
            <p className='font-semibold'>Kapodra   Shri M.B.Ausura   (P.I.Kapodra-1)   (M) 63596 29708</p>
            <p className='font-semibold'>Kapodra   Shri M.B.Ausura   (P.I.Kapodra-1)   (M) 63596 29708</p>
            <p className='font-semibold'>Kapodra   Shri M.B.Ausura   (P.I.Kapodra-1)   (M) 63596 29708</p>
          </div>
        </div>
      </div>
  )
}

export default Details
