import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';

export default function DashMenu({setActive}) {
  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <React.Fragment>
          <Button sx={{background: "#491e1e", color: "white", borderRadius: "0"}} {...bindTrigger(popupState)}>
            <p className='py-1 px-7 text-2xl'>Dashboard</p>
          </Button>
          <Menu {...bindMenu(popupState)}>
            <MenuItem onClick={() => {popupState.close();setActive("dashboard")}}>Dashboard</MenuItem>
            <MenuItem onClick={() => {popupState.close();setActive("police")}}>Police Station Details</MenuItem>
            <MenuItem onClick={() => {popupState.close();setActive("hospital")}}>Hospital Details</MenuItem>
            <MenuItem onClick={() => {popupState.close();setActive("volunteer")}}>Volunteer</MenuItem>
          </Menu>
        </React.Fragment>
      )}
    </PopupState>
  );
}
