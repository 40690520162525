import React from "react";
import TopCard from "./TopCard";
import Details from "./Details";

const Other = ({ name }) => {
    console.log("name", name);
    
  return (
    <div className="min-h-screen bg-gray-50 ">
      <div className="py-5 mx-auto max-w-9xl px-2 sm:px-6 lg:px-16">
        <div className="sm:flex sm:gap-16">
          <div
            className="py-1 mx-auto sm:mx-0 sm:py-0"
          >
            <TopCard other={name} />
          </div>
        </div>
        <Details other={name} />
      </div>
    </div>
  );
};

export default Other;
