import React, { useEffect, useState } from 'react'
import TopCard from '../../components/dashboard/TopCard'
import EmergencyForm from '../../components/dashboard/EmergencyForm';
import CreateRequest from '../../components/dashboard/CreateRequest';
import { api } from '../../components/utils/api';
import SnackbarWithDecorators from '../../Utils';

const Dashboard = () => {
    const [emergency, setEmergency] = useState(true);
    const [user, setUser] = useState(null);
    const [snackAlert, setSnackAlert] = useState(false); // popup success or error
    const [snackbarProperty, setSnackbarProperty] = useState({ // popup success or error text
        text: '',
        color: ''
    });
    console.log("Hello");
    const getUserDetails = () => {
        api("user", "get", false, false, true)
        .then((res)     => {
            console.log("res", res);
            if(res?.response?.status === 400){
                setSnackbarProperty(prevState => ({
                    ...prevState,
                    text: res.response.data.non_field_errors[0],
                    color: "danger"
                  }));
                setSnackAlert(true);
                  return;
            }else{
                console.log("res success", res.data);
                setUser(res.data)
                return;
            }
        }).catch((err) => {
            console.log("err", err);
        })
    }
    useEffect(() => {
        console.log("user", user);
    }, [user]);
    useEffect(() => {
        getUserDetails();
    }, []);
  return (
    <div className='min-h-screen bg-gray-50 '>
        {
            snackAlert?
            <SnackbarWithDecorators snackAlert={snackAlert} setSnackAlert={setSnackAlert} text={snackbarProperty.text} color={snackbarProperty.color} />
            :null
        }
        <div className='py-5 mx-auto max-w-9xl px-2 sm:px-6 lg:px-16'>
            <div className='sm:flex sm:gap-16'>
                <div className='py-1 mx-auto sm:mx-0 sm:py-0' onClick={() => setEmergency(true)}>
                    <TopCard from="emergency" />
                </div>
                <div className='py-1 mx-auto sm:mx-0 sm:py-0' onClick={() => setEmergency(false)}>
                    <TopCard from="not_emergency" />
                </div>
        </div>
        {
            emergency?<EmergencyForm user={user} />:<CreateRequest/>
        }
        </div>
    </div>
  )
}

export default Dashboard
