import React from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import FireTruckIcon from "@mui/icons-material/FireTruck";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";

const TopCard = ({ from, other }) => {
  return (
    <div className="flex flex-col gap-2 text-[10px] sm:text-xs z-50">
      <div
        className={`error-alert cursor-default flex items-center justify-between hover:bg-[#a44646] px-10 w-full h-20 sm:h-20 ${
          from === "emergency" ? "bg-[#491e1e]" : "bg-[#491e1e]"
        } px-[10px]`}
      >
        <div className="flex gap-2">
          {/* <div className="text-[#d65563] bg-[white/5] backdrop-blur-xl p-1 rounded-lg">
           {
                from === "emergency" ? <LocalFireDepartmentIcon className='cursor-pointer' />
                :<FireTruckIcon className='cursor-pointer' />
           }
        </div> */}
          <div>
            {
              !other ?
              (
                <>
                  <p className="text-white sm:text-2xl cursor-pointer">
                    {from === "emergency"
                      ? "Create Emergency Request"
                      : "Get All Emergency Call"}
                  </p>
                  <p className="text-gray-200 text-sm cursor-pointer">
                    {from === "emergency"
                      ? "Create request to Fire Station"
                      : "See all the alert Fire Station"}
                  </p>
                </>
              ):
                <>
                    <p className="text-white sm:text-2xl cursor-pointer px-10 ">
                    {
                      other === "police"
                      ? "Police Station details"
                      : other === "hospital"? "Hospital details":
                      "Volunteer details"
                    }
                  </p>
                </>
            }
          </div>
        </div>
        <button
          className={`${
            from === "emergency" ? "text-gray-600" : "text-gray-200"
          } hover:bg-white/10 p-1 rounded-md transition-colors ease-linear`}
        >
          {/* <ArrowForwardIosIcon /> */}
        </button>
      </div>
    </div>
  );
};

export default TopCard;
