import * as React from 'react';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import CellTowerIcon from '@mui/icons-material/CellTower';

export default function AssignModal({open, setOpen}) {
  return (
    <React.Fragment>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={open}
        onClose={() => setOpen(false)}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          // Ensure the modal takes up more width on larger screens
          width: { xs: '90%', sm: '80%', md: '70%', lg: '60%', xl: '50%' },
          margin: 'auto'
        }}
      >
        <Sheet
          variant="outlined"
          sx={{
            width: { xs: '90%', sm: '80%', md: '70%', lg: '60%', xl: '50%' }, // Ensuring responsive width
            borderRadius: 'md',
            p: 4, // Padding increased for better spacing in a larger modal
            boxShadow: 'lg'
          }}
        >
          <ModalClose variant="plain" sx={{ m: 1 }} />
          <Typography
            component="h2"
            id="modal-title"
            textColor="inherit"
            sx={{ fontWeight: 'bold' }} // Increased font size
          >
            <CellTowerIcon sx={{color: 'black'}} /> Fire Station Assigned Successfully
          </Typography>
          <Typography
            sx={{ mb: 2 }}
            id="modal-desc"
            textColor="text.tertiary"
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Waiting For Accept
          </Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<button
            type="button"
            className="focus:outline-none text-white bg-gray-900 rounded-lg hover:bg-[#a44646] text-sm px-5 py-1 me-2 mb-2"
          >
            <VerifiedUserIcon /> Accepted
          </button>
          <div className="flex justify-center mt-4">
            <button
                onClick={() => setOpen(false)}
              type="button"
              className="focus:outline-none text-white bg-gray-900 hover:bg-[#a44646] text-2xl px-12 py-1 me-2 mb-2"
            >
              OK
            </button>
          </div>
        </Sheet>
      </Modal>
    </React.Fragment>
  );
}
