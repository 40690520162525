import React, { useEffect, useState } from "react";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import { api } from "../utils/api";

const CreateRequest = () => {
  const [calls, setCalls] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterStatus, setFilterStatus] = useState("current"); // State for selected filter

  const getEmergencyCall = () => {
    setLoading(true);
    api("emergencycalls/", "get", false, false, true)
      .then((res) => {
          console.log("res", res);
          setCalls(res.data);
      })
      .catch((err) => {
          console.log("err", err);
      })
      .finally(() => {
          setLoading(false);
      });
  };

  useEffect(() => {
    getEmergencyCall();
  }, []);

  // Filter calls based on selected status
  const filteredCalls = calls.filter((item) => {
    if (filterStatus === "current") return true; // Show all for "current"
    return item.status.toLowerCase() === filterStatus.toLowerCase();
  });

  console.log("calls", filteredCalls);

  const IsAccepted = (request) => {
    return (
      <button
        title="Save"
        className={`cursor-pointer flex items-center ${
          request === "Accepted"
            ? "fill-lime-400 bg-lime-950 hover:bg-lime-900 active:border active:border-lime-400"
            : "fill-red-400 bg-red-950 hover:bg-red-900 active:border active:border-red-400"
        } rounded-md duration-100 p-2`}
      >
        <span
          className={`text-sm ${
            request === "Accepted" ? "text-lime-400" : "text-red-400"
          } font-bold pr-1`}
        >
          {request}
        </span>
      </button>
    );
  };

  return (
    <>
      <div className="my-5">
        <div className="shadow-custom p-5 w-full bg-white">
          <div className="flex justify-between">
            <p className="font-bold text-slate-700">All Emergency Calls</p>
            <select
              name="priority"
              id="priority"
              className="max-w-52 bg-[#a44646] border border-gray-300 text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              value={filterStatus} // Bind value to state
              onChange={(e) => setFilterStatus(e.target.value)} // Update state on change
            >
              <option value="current">All</option>
              <option value="Assigned">Assigned</option>
              <option value="Pending">Pending</option>
              <option value="Accepted">Accepted</option>
            </select>
          </div>
          <hr className="my-2" />
          {loading ? (
            <p>Loading...</p>
          ) : (
            filteredCalls &&
            filteredCalls.map((item, index) => {
              return (
                <div
                  key={item.id}
                  className="text-[12px] sm:text-[16px] my-5 w-full cursor-pointer overflow-hidden relative transition-all duration-500 hover:translate-y-2 h-44 bg-[#d9d9d9] rounded-lg shadow-custom flex flex-row items-center justify-evenly gap-2 p-2"
                >
                  <div className="w-[100%] p-5">
                    <div className="flex justify-between sm:text-xl">
                      <span className="font-bold">
                        Caller: {item.caller_name} ({item.caller_phone_number})
                      </span>
                      <span className="text-right text-xl font-bold pr-1">
                        ID {item.id}
                      </span>
                    </div>
                    <p className="line-clamp-3">
                      Location: {item.incident_location} ({item.incident_type})
                    </p>
                    <p className="line-clamp-3">
                      Landmark: {item.nearest_landmark} ({item.incident_type})
                    </p>
                    <p className="text-right text-red-600 font-semibold">
                      {item.status} to{" "}
                      {item.nearest_fire_stations.find(
                        (station) => station.id === item.fire_station
                      )?.name || "(no fire station found)"}
                    </p>
                  </div>
                  <div>{/* {IsAccepted(item.status)} */}</div>
                </div>
              );
            })
          )}
        </div>
      </div>
    </>
  );
};

export default CreateRequest;
