import logo from './logo.svg';
import './App.css';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import Login from './pages/auth/Login';
import Navbar from './components/Navbar';
import Dashboard from './pages/dashboard/Dashboard';
import ProtectedRoute from './components/utils/ProtectedRoute';
import { useEffect, useState } from 'react';
import Other from './components/dashboard/Other';

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const [active, setActive] = useState("dashboard");
  const activeTab = () => {
    if(active === "dashboard") {
      return <Dashboard/>
    }else{
      return <Other name={active} />
    }
  }
  useEffect(() => {
    if(location.pathname === "/"){
      navigate("/emergency_call");
    }
  }, []);
  return (
    <div className="App">
      {location.pathname !== "/login" && <Navbar setActive={setActive} />}
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route
          path='/emergency_call'
          element={
            <ProtectedRoute>
              {activeTab()}
            </ProtectedRoute>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
